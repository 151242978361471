<template>
    <v-chart ref="chart" class="chart" :option="option" />
  </template>
  
  <script>
  import { use } from "echarts/core";
  import { CanvasRenderer } from "echarts/renderers";
  import { GaugeChart } from "echarts/charts";
  import {
    TitleComponent,
    TooltipComponent,
    LegendComponent,
    ToolboxComponent
  } from "echarts/components";
  import VChart from "vue-echarts";
  
  use([
    CanvasRenderer,
    GaugeChart,
    TitleComponent,
    TooltipComponent,
    LegendComponent,
    ToolboxComponent
  ]);
  
  export default {
    components: {
      VChart
    },
    props: {
      totalCreditLimit: {
        type: Number,
        required: true,
      },
      creditUsed: {
        type: Number,
        required: true
      },
      ranks: {
        type: Array,
        required: true
      },
      credits: {
        type: Array,
      },
      warningPercentage: {
        type: Number,
        required: true
      }
    },
    computed: {
      usageRatio() {
      if (this.creditUsed === 0 || this.totalCreditLimit === 0) {
        return 0; // Avoid showing NaN when creditUsed or totalCreditLimit is 0
      } else {
        return ((this.creditUsed / this.totalCreditLimit) * 100).toFixed(2);
      }
      },
      dynamicColors() {
        const greenEnd = this.warningPercentage / 100;
        const orangeEnd = greenEnd + (1 - greenEnd) / 2;
        const colors = [
          [greenEnd, "#D6D6D6"], // Green
          [orangeEnd, "orange"], // Orange
          [1, "red"], // Red
        ];
        return colors;
      },
      // dynamicLabels() {
      //   const labels = {};
      //   console.log('this.credits')
      //   console.log(this.credits)
      //   // let labels = []
      //   const totalRanks = this.credits.length;
      //   const step = totalRanks > 1 ? 100 / (totalRanks - 1) : 0;
      //   for (let i = 0; i < totalRanks; i++) {
      //     labels[this.credits[i].rank] = `Rank ${this.credits[i].rank}`;
      //   }

      //   console.log(labels)
      //   return labels;
      // }
    },
    watch: {
      usageRatio(newValue) {
      if (newValue > this.warningPercentage) {
        this.$message.error(`Credit Usage over ${this.warningPercentage}!`);
      }
      this.updateGauge();
    },
    ranks(newRanks) {
        // console.log("Ranks being passed to GaugeChart:", newRanks);
        this.updateGauge();
      },
      warningPercentage() {
        this.updateGauge();
      }
    },
    data() {
      return {
        option: {
          tooltip: {
            formatter: '{a} <br/>{b} : {c}%'
          },
          series: [
            {
              name: 'Credit Guage',
              type: 'gauge',
              startAngle: 180,
              endAngle: 0,
              detail: {
                formatter: '{value}%',
                color: '#D6D6D6'
              },
              axisLine: {
                lineStyle: {
                  width: 5,
                  // color: []
                }
              },
              splitLine: {
                distance: 10,
                length: 10,
                lineStyle: {
                  color: 'auto'
                }
              },
              pointer: {
                width: 5
              },
              axisTick: {
                distance: 5,
                length: 8,
                lineStyle: {
                  color: 'auto'
                }
              },
              axisLabel: {
                color: 'inherit',
                distance: 5,
                fontSize: 2,
                rotate: 'tangential',
                // formatter: (value) => {
                //   const labels = this.dynamicLabels;
                //   return labels[value] || '';
                // }
              },
              title: {
                color: "#D6D6D6",
                fontSize: 12,
              },
              data: [{value: 0, name: "Credit Usage" }],
              radius: '90%', // Increase radius for better use of space
              center: ['50%', '50%'], // Center the gauge
            }
          ]
        }
      };
    },
    methods: {
      updateGauge() {
        if (!this.ranks || this.ranks.length === 0) {
          console.warn("No ranks provided to the GaugeChart.");
          return;
        }
        this.option.series[0].data[0].value = this.usageRatio;
        this.option.series[0].axisLine.lineStyle.color = this.dynamicColors;
        // this.option.series[0].axisLabel.formatter = (value) => {
        //   const labels = this.dynamicLabels;
        //   return labels[value] || '';
        // };
        if (this.$refs.chart) {
          this.$refs.chart.setOption(this.option, true);
        } else {
          console.warn("Chart ref is not available.");
        }
      }
    },
    // mounted() {
    //   if (this.ranks && this.ranks.length > 0) {
    //     this.updateGauge();
    //   }
    // }
  };
  </script>
  
  <style scoped>
  .chart {
    height: 250px !important; 
    width: 250px !important; 
    max-width: 250px;
    margin: 0 auto; 
  }
  
  @media (max-width: 1200px) {
    .chart {
      max-width: 250px;
    }
  }
  
  @media (max-width: 900px) {
    .chart {
      max-width: 200px;
    }
  }
  
  @media (max-width: 768px) {
    .chart {
      max-width: 180px;
    }
  }
  
  @media (max-width: 600px) {
    .chart {
      max-width: 150px;
    }
  }
  
  @media (max-width: 480px) {
    .chart {
      max-width: 120px;
    }
  }
  </style>